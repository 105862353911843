export function renderColorStatus(value: number) {
    switch (value) {
        case 0:
            return 'warning';
        case 1:
            return 'info';
        case 2:
            return 'error';
        case 3:
            return 'success';
        case 4:
            return 'info';
        case 5:
            return 'error';
        case 6:
            return 'info';
    }
};

export function renderStatus(value: number) {
    switch (value) {
        case 0:
            return 'در انتظار پرداخت';
        case 1:
            return 'درحال انجام';
        case 2:
            return 'ناموفق';
        case 3:
            return 'تکمیل شده';
        case 4:
            return 'باز گردانی شده';
        case 5:
            return 'لغو شده';
        case 6:
            return 'درانتظار لینک';
    }
};

export enum StatusEnum {
    Pending,
    Proccess,
    Failed,
    Done,
    Refund,
    Cancel
}