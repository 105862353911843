import { Box, CircularProgress, Grid } from "@mui/material"
import React from "react"
import './PaymentReport.scss'
import { CustomButton } from "src/components/shared/Button/button"
import AgentOrderPackageService, { OrderDto } from "src/services/sales-panel/AgentOrderPackageService"
import Smoking from '../../../assets/images/Apple98-Logo-smoking.gif'
import Recipe from '../../../assets/icons/recipe - black.png'
import Calendar from '../../../assets/icons/calendar - black.png'
import Iphonex from '../../../assets/icons/iphonex - black.png'
import Edit from '../../../assets/icons/edit name - black.png'
import Clock from '../../../assets/icons/clock - black.png'
import Atsign from '../../../assets/icons/atsign - black.png'
import Alert from '../../../assets/icons/alert - black - big.png'
import ConfigService from "src/services/ConfigService"
import moment from 'jalali-moment';
import { StatusEnum } from "src/components/order/Enum/StatusEnum"
import QrCodeGenerator from "src/components/shared/qr-code/QrCodeGenerator"
import { toast } from "react-toastify"
import { NavLink } from "react-router-dom"
import { returnBaseUrl } from "src/constants/constants"

export default class PaymentReport extends React.PureComponent<{ nextState: () => void, logout: () => void, orderNumber: string, trackId?: string },
    { orderData?: OrderDto, loading: boolean, pendingRequest: boolean, successFactorDescription: string, failedFactorDescription: string, link?: string, interval?: any, tokenCustomer?: string }> {

    agentOrderPackageService: AgentOrderPackageService;

    configService: ConfigService;

    constructor(props: any) {
        super(props)
        this.state = { loading: false, pendingRequest: false, failedFactorDescription: '', successFactorDescription: '' }

        this.agentOrderPackageService = new AgentOrderPackageService();

        this.configService = new ConfigService();
    }

    componentDidMount(): void {

        this.setState({ loading: true, tokenCustomer: localStorage.getItem('access-token-customer') }, () => console.log(this.state.tokenCustomer))

        if (this.props.trackId)
            this.agentOrderPackageService.payment(this.props.orderNumber, this.props.trackId).then((response) => {
                this.setState({ orderData: response })
            }).finally(() => this.setState({ loading: false }))

        this.setConfig()

        this.trackingOrder()

        let interval = setInterval(() => {
            if (!this.state.link)
                this.trackingOrder()
        }, 10000);
        this.setState({ interval: interval })
    }

    componentWillUnmount(): void {
        clearInterval(this.state.interval);
    }

    trackingOrder = () => {
        this.agentOrderPackageService.tracking(this.props.orderNumber)
            .then((response) => {
                this.setState({ link: response.inviteLink })
            }).finally(() => {
                if (!this.props.trackId) this.setState({ loading: false })
            })
    }

    tryAgainOrder = () => {
        this.setState({ pendingRequest: true })
        let callbackUrl = `${window.location.origin}/${window.location.pathname}`

        this.agentOrderPackageService.tryAgain(this.props.orderNumber, callbackUrl)
            .then((response) => {
                window.location.href = response;
            })
            .finally(() => this.setState({ pendingRequest: true }))
    }

    setConfig = () => {
        this.configService.get()
            .then((response) => {
                let failed = response.filter(item => item.key == 'FAILD_FACTOR_DESCRIPTION')[0].value;
                let success = response.filter(item => item.key == 'SUCCESS_FACTOR_DESCRIPTION')[0].value;

                this.setState({ successFactorDescription: success, failedFactorDescription: failed })
            })
    }

    copyTextToClipboard = async (text: string) => {
        if ("clipboard" in navigator) {
            toast.info("Copied.", { style: { width: "200px" } })
            return navigator.clipboard.writeText(text)
        }
    }

    successful = () => {
        let { orderData, successFactorDescription, link } = this.state

        return (<>
            <Box sx={{ p: 2, mb: 2 }} color="grey" className="box-payment full-width">
                <div id="wrapper">
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="recipe icon" className="ml-sm icon-img-md" src={Recipe} />
                            <label className="font-bold">شماره فاکتور :</label>
                        </div>
                        <span className="fa-number-fat">{orderData?.orderNumber}</span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="calendar icon" className="ml-sm icon-img-md" src={Calendar} />
                            <label className="font-bold">تاریخ خرید :</label>
                        </div>
                        <span className="fa-number-fat">{moment(orderData?.buyTime).locale('fa').format('HH:mm - YYYY/M/D')} </span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="iphone icon" className="ml-sm icon-img-md" src={Iphonex} />
                            <label className="font-bold">شماره‌موبایل :</label>
                        </div>
                        <span className="fa-number-fat">{orderData?.phoneNumber}</span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="edit icon" className="ml-sm icon-img-md" src={Edit} />
                            <label className="font-bold">نام محصول :</label>
                        </div>
                        <span className="font-heavy">{orderData?.title}</span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="clock icon" className="ml-sm icon-img-md" src={Clock} />
                            <label className="font-bold">مدت اشتراک :</label>
                        </div>
                        <div className="font-heavy">
                            <span className="fa-number-fat pl-sm">{orderData.duration}</span>
                            <span className="font-heavy">روز</span>
                        </div>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="apple id icon" className="ml-sm icon-img-md" src={Atsign} />
                            <label className="font-bold">اپل آیدی :</label>
                        </div>
                        <span className="font-bold"></span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div></div>
                        <span className="font-heavy">{orderData?.email}</span>
                    </div>
                </div>
                <div className="mt-md flex justify-end full-width">
                    <NavLink to="/sales-agent/cutomer">
                        <CustomButton color='info' onClick={() => { this.props.logout() }} variant="outlined" size="small">بازگشت</CustomButton>
                    </NavLink>
                    {
                        this.state.pendingRequest ?
                            <CustomButton color='inherit' sx={{ mr: 1 }} variant="contained" size="small">
                                <CircularProgress color="info" size={20} />
                            </CustomButton> :
                            <CustomButton color='info' sx={{ mr: 1 }} disabled variant="contained" size="small">دانلود فاکتور</CustomButton>
                    }
                </div>
            </Box>
        </>)
    }

    failed = () => {
        let { orderData, failedFactorDescription } = this.state

        return (<>
            <img alt="auth logo" src={Alert} className="status-img" />
            <span className="size-6 font-heavy">پرداخت ناموفق بود</span>
            <Box sx={{ p: 2, mt: 2 }} color="grey" className="box-payment full-width">
                <div id="wrapper">
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="recipe icon" className="ml-sm icon-img-md" src={Recipe} />
                            <label className="font-bold">شماره فاکتور :</label>
                        </div>
                        <span className="fa-number-fat">{orderData?.orderNumber}</span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="calendar icon" className="ml-sm icon-img-md" src={Calendar} />
                            <label className="font-bold">تاریخ خرید :</label>
                        </div>
                        <span className="fa-number-fat">{moment(orderData?.buyTime).locale('fa').format('HH:mm - YYYY/M/D')} </span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="iphone icon" className="ml-sm icon-img-md" src={Iphonex} />
                            <label className="font-bold">شماره‌موبایل :</label>
                        </div>
                        <span className="fa-number-fat">{orderData?.phoneNumber}</span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="edit icon" className="ml-sm icon-img-md" src={Edit} />
                            <label className="font-bold">نام محصول :</label>
                        </div>
                        <span className="font-heavy">{orderData?.title}</span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="clock icon" className="ml-sm icon-img-md" src={Clock} />
                            <label className="font-bold">مدت اشتراک :</label>
                        </div>
                        <div className="font-heavy">
                            <span className="fa-number-fat pl-sm">{orderData.duration}</span>
                            <span className="font-heavy">روز</span>
                        </div>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div className="flex">
                            <img alt="apple id icon" className="ml-sm icon-img-md" src={Atsign} />
                            <label className="font-bold">اپل آیدی :</label>
                        </div>
                        <span className="font-bold"></span>
                    </div>
                    <div className="flex space-between align-center pb-md">
                        <div></div>
                        <span className="font-heavy">{orderData?.email}</span>
                    </div>
                    <div className="description">
                        <div className="text-justify" dangerouslySetInnerHTML={{ __html: failedFactorDescription }}></div>
                    </div>
                </div>
                <div className="mt-md flex justify-end full-width">
                    <NavLink to="/sales-agent/cutomer">
                        <CustomButton color='info' onClick={() => { this.props.logout() }} variant="outlined" size="small">ثبت سفارش جدید</CustomButton>
                    </NavLink>
                    {
                        this.state.pendingRequest ?
                            <CustomButton color='inherit' sx={{ mr: 1 }} variant="contained" size="small">
                                <CircularProgress color="info" size={20} />
                            </CustomButton> :
                            <CustomButton color='info' sx={{ mr: 1 }} variant="contained" size="small" onClick={this.tryAgainOrder}>امتحان دوباره</CustomButton>
                    }
                </div>
            </Box>
        </>)
    }

    render(): React.ReactNode {
        let { loading, orderData, link } = this.state;
        let isSuccess: boolean = (this.props.trackId && orderData) && [StatusEnum.Proccess, StatusEnum.Done].includes(orderData.orderStatus);

        return <Box width={'400px'} className="flex column justify-center align-center element-center">
            {
                loading ?
                    <Grid m={5} display='flex' justifyContent={'center'} alignItems={'center'}>
                        <CircularProgress color="inherit" size={42} />
                    </Grid> :
                    <>
                        {
                            (isSuccess || !this.props.trackId) &&
                            <>
                                <img alt="auth logo" src={Smoking} className="status-img" />
                                {
                                    this.props.trackId ?
                                        <span className="size-6 font-heavy">خرید شما با موفقیت انجام شد</span> :
                                        <span className="size-6 font-heavy">پیگیری سفارش</span>
                                }
                                <br />
                                <Box sx={{ py: 1, px: 2, mb: 1 }} color="grey" className="box-payment full-width">
                                    <div className="font-bold"> لینک اشتراک مشتری شما :</div>
                                    <div className="flex justify-center column align-center mt-sm">
                                        {
                                            link ?
                                                <>
                                                    <div className="text-center">
                                                        <QrCodeGenerator keyGenerator={link} />
                                                        <p className="text-muted">{link}</p>
                                                    </div>
                                                    <CustomButton color='info' disabled={!link} onClick={() => this.copyTextToClipboard(link)} variant="contained" size="small">کپی لینک اشتراک</CustomButton>
                                                </>
                                                :
                                                <>
                                                    <p className="font-heavy text-center">
                                                        درحال دریافت ...
                                                    </p>
                                                    <div className="pr-sm">
                                                        <NavLink to="/sales-agent/cutomer">
                                                            <CustomButton color='info' onClick={() => { this.props.logout() }} variant="contained" size="small">ثبت سفارش جدید</CustomButton>
                                                        </NavLink>
                                                    </div>
                                                    {
                                                        this.state.tokenCustomer &&
                                                        <NavLink target="_blank" to={`/sales-agent/cutomer?orderNumber=${this.props.orderNumber}`}>
                                                            <CustomButton color='info' onClick={() => { this.props.logout() }} variant="outlined" size="small">پیگیری سفارش در تب جدید</CustomButton>
                                                        </NavLink>
                                                    }
                                                </>
                                        }
                                    </div>
                                </Box>
                            </>
                        }
                        {
                            (orderData) && <>
                                {isSuccess ? this.successful() : this.failed()}
                            </>
                        }
                    </>
            }
        </Box>
    }
}