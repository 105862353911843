
import { Box, Container } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import SectionView from "src/components/shared/SectionView/SectionView";
import SignInOtp from "../components/identity/sign-in-otp/SignInOtp";
import CompletionInformation from "../components/identity/completion-information/CompletionInformation";
import Packages from "../components/packages/Packages";
import PackageDetails from "../components/packages/details/PackageDetails";
import PaymentReport from "../components/payment/PaymentReport";
import AuthenticationService from "src/services/AuthenticationService";
import Banner from '../../assets/images/banners/tgbanner.jpg'
import authenticationContext from "../../contexts/AuthenticationContext";

export default function SalesCustomersRoute() {
    let saleCustomerState = localStorage.getItem('sale-customer-state') ?? 0;
    let tokenCustomer = localStorage.getItem('access-token-customer') ?? 0;
    const authenticationService = new AuthenticationService();
    const [state, setState] = useState(Number(saleCustomerState))
    const [userInformation, setUserInformation] = useState(null)
    const [packageId, setPackageId] = useState(null)
    const queryParameters = new URLSearchParams(window.location.search)
    const orderNumber = queryParameters.get("orderNumber")
    const trackId = queryParameters.get("trackId")
    const { privileges, roles } = useContext(authenticationContext);

    useEffect(() => {
        if (orderNumber != null) nextStep(4)
        else if (state == 3) prevStep()
        if (tokenCustomer && state != 0)
            fetchInformation()
    }, []);

    const fetchInformation = () => {
        authenticationService.getUserInformationByToken()
            .then(response => {
                if (response)
                    setUserInformation(response)
            })
    }

    const nextStep = (newState?: number) => {
        setState(newState ?? state + 1)
        localStorage.setItem('sale-customer-state', `${newState ?? state + 1}`);
    }

    const prevStep = () => {
        setState(state - 1)
        localStorage.setItem('sale-customer-state', `${state - 1}`);
    }

    const logout = () => {
        setUserInformation(null)
        localStorage.removeItem('sale-customer-state');
        localStorage.removeItem('access-token-customer');
        nextStep(0)
    }

    const renderBody = () => {
        switch (state) {
            case 0:
                return <SignInOtp nextState={nextStep} onLogged={fetchInformation} />;
            case 1:
                return <CompletionInformation nextState={nextStep} logout={logout} />;
            case 2:
                return <Packages redirectToProduct={(id) => {
                    setPackageId(id)
                    nextStep()
                }} nextState={nextStep} />
            case 3:
                return <PackageDetails customerUserId={userInformation?.id} prevState={prevStep} nextState={nextStep} packageId={packageId} />
            case 4:
                return <PaymentReport trackId={trackId} orderNumber={orderNumber} logout={logout} nextState={nextStep} />
        }
    }

    return (
        <>
            <Helmet>
                <title>اپل98 - فروش به مشتری</title>
            </Helmet>
            <Container sx={{ pt: '100px' }}>
                <SectionView title={userInformation && userInformation.firstName ? `فروش به مشتری ( ${userInformation?.firstName} ${userInformation?.lastName} ) ` : "فروش به مشتری"}>{renderBody()}
                </SectionView>
                <Box sx={{ display: 'flex', pt: '20px', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <a href={roles.includes("MASTER_SALES_AGENT") ? "https://t.me/+GRkY9aQm5T43OWMx" : "https://t.me/+2owd1ENpbiVmN2Ex"}>
                        <img src={Banner} style={{width: '700px',  maxWidth: '100%' }} />
                    </a>
                </Box>
            </Container>
        </>
    )
}