// const DEV_API_URL = 'http://localhost:5079';
const DEV_API_URL = 'https://apple98.net';
const PROD_API_URL = 'https://apple98.net';

export const returnBaseUrl = () => {
  const url = window.location.origin;

  if (process.env.NODE_ENV === 'development') return DEV_API_URL;
  else return PROD_API_URL;
};

// const DEV_API_AUTHENTICATION_URL = 'http://localhost:5138';
const DEV_API_AUTHENTICATION_URL = 'https://auth.apple98.net';
const PROD_API_AUTHENTICATION_URL = 'https://auth.apple98.net';

export const returnAuthenticationUrl = () => {
  const url = window.location.origin;

  if (process.env.NODE_ENV === 'development') return DEV_API_AUTHENTICATION_URL;
  else return PROD_API_AUTHENTICATION_URL;
};